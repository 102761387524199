import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PURCHASE_BILL } from '@utils/constants/purchase/purchase-bill.constants'
import { baseQueryWithReauth } from '../auth/auth.api';

export const purchaseBillApi = createApi({
    reducerPath: 'purchaseBillApi',
    tagTypes: ['PurchaseBill'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllPurchaseBills: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                search_key?: string;
                from_date?: any,
                to_date?: any,
                status?: number
            }
        >({
            query: ({ page = 1, limit = 10, search_key, from_date, to_date, status }) => {
                const queryParams = {
                    url: PURCHASE_BILL.GET_ALL,
                    params: { page, limit, search_key, from_date, to_date, status },
                };

                return queryParams;
            },
        }),
        getPurchaseBill: builder.query<
            any,
            { bill_id: string | number }
        >({
            query: ({ bill_id }) => {
                console.log(
                    `${PURCHASE_BILL.GET_ONE}${bill_id}`
                );
                return `${PURCHASE_BILL.GET_ONE}${bill_id}`;
            },
        }),
        createPurchaseBill: builder.mutation({
            query: (body) => ({
                url: PURCHASE_BILL.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updatePurchaseBill: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PURCHASE_BILL.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deletePurchaseBill: builder.mutation({
            query: (id) => ({
                url: `${PURCHASE_BILL.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor: 0
});

export const {
    useGetAllPurchaseBillsQuery,
    useGetPurchaseBillQuery,
    useCreatePurchaseBillMutation,
    useUpdatePurchaseBillMutation,
    useDeletePurchaseBillMutation,
} = purchaseBillApi;
