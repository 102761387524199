import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type CommonState = {
    formValues: any;
    modules: any[];
    subModules: any[];
    regions: any[];
    ways: any[];
    countries: any;
    states: any[];
    cities: any[];
    townships: any[];
    businessUnits: any[];
    regionDetails: any[];
    userRoleAssignDetails: any[];
    wayDetails: any[];
    tripDetails: any[];
    productDetails: any[];
    deletedRegionDetails: any[];
    deletedWayDetails: any[];
    deletedUserRoleAssignDetails: any[];
    deletedWays: any[];
    assignModules:any[];
    sideBarCollapse: boolean;
}

const initialState: CommonState = {
    formValues: {},
    modules: [],
    subModules: [],
    regions: [],
    ways: [],
    countries: [],
    states: [],
    cities: [],
    townships: [],
    businessUnits: [],
    regionDetails: [],
    wayDetails: [],
    tripDetails: [],
    userRoleAssignDetails: [],
    productDetails: [],
    deletedRegionDetails: [],
    deletedWayDetails: [],
    deletedUserRoleAssignDetails: [],
    deletedWays: [],
    assignModules:[],
    sideBarCollapse: false,
};

const commonSlice = createSlice({
    name: 'common',
    initialState: initialState,
    reducers: {
        toggleSideBarCollapse(state) {
            state.sideBarCollapse = !state.sideBarCollapse;
        },
        addFormValues(state, action: PayloadAction<any>) {
            state.formValues = action.payload;
        },
        addModules(state, action: PayloadAction<any[]>) {
            state.modules = action.payload;
            console.log(action.payload, 'okay');

            // filter sub modules and assign details
            if (state.subModules.length > 0) {
                const moduleIds = state.modules.map((m) =>
                    parseInt(m.module_id)
                );

                const updatedSubModules = state.subModules.filter((sm) =>
                    moduleIds.includes(parseInt(sm.module_id))
                );

                const updatedDetails = state.userRoleAssignDetails.filter((d) =>
                    moduleIds.includes(parseInt(d.module_id))
                );

                state.subModules = updatedSubModules;
                state.userRoleAssignDetails = updatedDetails;
            }
        },
        addSubModules(state, action: PayloadAction<any[]>) {
            const subModules = action.payload;

            const withModuleName = subModules.map((subModule) => {
                return {
                    ...subModule,
                    module_name: state.modules.find(
                        (module) => module.module_id == subModule.module_id
                    ).module_name,
                };
            });

            state.subModules = withModuleName;

            state.userRoleAssignDetails = subModules.map((sm) => ({
                user_role_id: state.formValues.user_role_id,
                channel_id: state.formValues.channel_id,
                module_id: sm.module_id,
                sub_module_id: sm.sub_module_id,
                system_id: 1, // TODO: remove system but need to confirm fist
            }));
        },
        addProductDetail(state, action: PayloadAction<any>) {
            state.productDetails.push(action.payload);
        },
        replaceProductDetail(state, action: PayloadAction<any>) {
            state.productDetails = action.payload;
        },
        // business unit
        addBusinessUnits(state, action: PayloadAction<any[]>) {
            state.businessUnits = action.payload;
        },
        removeBusinessUnit(state, action: PayloadAction<number | string>) {
            const newBusinessUnits = state.businessUnits.filter(
                (bu) => bu.business_unit_id != action.payload
            );

            state.businessUnits = newBusinessUnits;
        },
        addRegions(state, action: PayloadAction<any[]>) {
            state.regions = action.payload;
        },
        addWays(state, action: PayloadAction<any[]>) {
            state.ways = action.payload;
        },
        addWayDetails(state, action: PayloadAction<any[]>) {
            state.wayDetails = action.payload.map((wd, i) => ({
                ...wd,
                way_details_id: wd.way_details_id || i,
            }));
        },
        addTripDetails(state, action: PayloadAction<any[]>) {
            state.tripDetails = action.payload.map((td, i) => ({
                ...td,
                trip_details_id: td?.trip_details_id || i,
            }));
        },
        removeRegion(state, action: PayloadAction<any>) {
            const newRegions = state.regions.filter(
                (r) => r.region_id != action.payload.region_id
            );
            state.regions = newRegions;

            state.deletedWayDetails.push(action.payload.way_details_id);
        },
        removeRegionDetail(state, action: PayloadAction<any>) {
            console.log(action.payload, state.regions);
            // const newRegions = state.regions.filter(
            //     (r) => r.region_id != action.payload.region_id
            // );
            // state.regions = newRegions;

            // state.deletedWayDetails.push(action.payload.way_details_id);
        },
        deleteRegionDetail(state, action: PayloadAction<any>) {
            const toDelete = action.payload;

            state.deletedRegionDetails.push(toDelete.region_detail_id);

            // region detail
            const newRegionDetails = state.regionDetails.filter(
                (rd) => rd.region_detail_id != toDelete.region_detail_id
            );
            state.regionDetails = newRegionDetails;

            // township
            const newTownships = state.townships.filter(
                (t) =>
                    !(
                        t.state_id == toDelete.state_id &&
                        t.city_id == toDelete.city_id &&
                        t.township_id == toDelete.township_id
                    )
            );
            state.townships = newTownships;

            // city
            const doesCityExist = newRegionDetails.find(
                (nrd) => nrd.city_id == toDelete.city_id
            );

            if (!doesCityExist) {
                const newCities = state.cities.filter(
                    (t) =>
                        !(
                            t.state_id == toDelete.state_id &&
                            t.city_id == toDelete.city_id
                        )
                );
                state.cities = newCities;
            }

            // state
            const doesStateExist = newRegionDetails.find(
                (nrd) => nrd.state_id == toDelete.state_id
            );

            if (!doesStateExist) {
                const newStates = state.states.filter(
                    (t) => !(t.state_id == toDelete.state_id)
                );
                state.states = newStates;
            }
        },
        emptyRegionDetail(state) {
            state.regionDetails = [];
        },
        emptyWayDetails(state) {
            state.wayDetails = [];
        },
        emptyTripDetails(state) {
            state.tripDetails = [];
        },
        emptyUserRoleAssignDetails(state) {
            state.userRoleAssignDetails = [];
        },
        resetFormValues(state) {
            state.formValues = {};
        },
        addCountries(state, action: PayloadAction<any[]>) {
            console.log(action.payload);
            state.countries = action.payload;
        },
        addStates(state, action: PayloadAction<any[]>) {
            state.states = action.payload;
            state.regionDetails.push(
                action.payload.map((s) => ({
                    state_id: s.state_id,
                    state_name: s.name,
                    country_id: s.country_id,
                    country_name: s.country_name
                }))
            );
        },
        addCities(state, action: PayloadAction<any[]>) {
            state.cities = action.payload;
            state.regionDetails = action.payload.map((c) => ({
                state_id: c.state_id,
                state_name: c.state_name,
                city_id: c.city_id,
                city_name: c.cityname,
            }));
        },
        addTownships(state, action: PayloadAction<any[]>) {
            state.townships = action.payload;
            state.regionDetails = action.payload.map((t, i) => ({
                region_detail_id: t.region_detail_id || i,
                state_id: t.state_id,
                state_name: t.state_name,
                city_id: t.city_id,
                city_name: t.city_name,
                township_id: t.township_id,
                township_name: t.townshipname,
            }));
        },
        removeSubModule(state, action: PayloadAction<any>) {
            const newSubModules = state.subModules.filter(
                (sm) => sm.sub_module_id != action.payload
            );

            const newUserRoleAssignDetails = state.userRoleAssignDetails.filter(
                (dtl) => dtl.sub_module_id != action.payload
            );

            const newModuleIds = newSubModules.map((sm) => sm.module_id);

            const newModules = state.modules.filter((m) =>
                newModuleIds.includes(m.module_id)
            );

            state.modules = newModules;
            state.subModules = newSubModules;
            state.userRoleAssignDetails = newUserRoleAssignDetails;
            state.deletedUserRoleAssignDetails.push(action.payload);
        },
        emptyDeletedUserRoleAssignDetails(state) {
            state.deletedUserRoleAssignDetails = [];
        },
        updateProductDetail(state, action: PayloadAction<any>) {
            const { index, newProductDetail } = action.payload;

            state.productDetails[index] = newProductDetail;
        },
        removeProductDetail(state, action: PayloadAction<any>) {
            const updateProductDetails = state.formValues.product_detail.filter(
                (_: any, i: number) => i !== action.payload
            );

            state.formValues.product_detail = updateProductDetails;
        },
        removeProducts(state, action: PayloadAction<any>) {
            const updateProductDetails = state.formValues.products.filter(
                (_: any, i: number) => i !== action.payload
            );

            state.formValues.products = updateProductDetails;
        },
        removeWay(state, action: PayloadAction<number | string>) {
            state.deletedWays.push(action.payload);
        },
        addAssignModule(state,action:PayloadAction<any[]>){
            state.assignModules = action.payload
        },
        removeAssignModule(state){
            state.assignModules = []
        }
    },
});

export const {
    addModules,
    addSubModules,
    addRegions,
    addWays,
    addCountries,
    addStates,
    addCities,
    addTownships,
    addBusinessUnits,
    removeBusinessUnit,
    removeRegion,
    removeRegionDetail,
    deleteRegionDetail,
    emptyRegionDetail,
    addWayDetails,
    addTripDetails,
    emptyWayDetails,
    emptyTripDetails,
    addFormValues,
    emptyUserRoleAssignDetails,
    resetFormValues,
    removeSubModule,
    emptyDeletedUserRoleAssignDetails,
    addProductDetail,
    updateProductDetail,
    removeProductDetail,
    removeWay,
    replaceProductDetail,
    addAssignModule,
    removeAssignModule,
    removeProducts,
    toggleSideBarCollapse
} = commonSlice.actions;

export default commonSlice.reducer;

export const sideBarCollapse = (state: any) => state.common.sideBarCollapse;
