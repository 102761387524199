import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { useGetAllCountryQuery, useGetAllStateQuery, useGetAllCityQuery } from '@states/common/common.api';

type Props = {
    search: {
        country?: string,
        state?: string,
        city?: string
    }
}

const useLocationEffect = (props?: Props) => {
    const {
        countries,
        states: storedStates,
    } = useSelector((state: RootState) => state.common);

    const { data: countryData, isLoading: countryLoading } = useGetAllCountryQuery({
        search_key: props?.search.country
    });

    const { data: stateData, isLoading: stateLoading, refetch: stateRefetch } = useGetAllStateQuery({
        search_key: props?.search.state,
        country_id: countries[0]?.country_id
    });

    const { data: cityData, isLoading: cityLoading } = useGetAllCityQuery({
        search_key: props?.search.city,
        state_id: storedStates[0]?.state_id
    });

    return {
        countries: countryData ?? [],
        cities: cityData ?? [], 
        states: stateData ?? [], 
        isLoading: (countryLoading || stateLoading || cityLoading) 
    };
};

export default useLocationEffect;