import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { SALES_RETURN } from '@/utils/constants/sales-return/sales-return.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const secondarySalesReturnApi = createApi({
    reducerPath: 'secondarySalesReturnApi',
    tagTypes: ['SecondarySalesReturn'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllSecondarySaleReturns: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                search_key?: string;
                status?: number;
                from_date?: any;
                to_date?: any
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                search_key,
                status,
                from_date,
                to_date
            }) => {
                const queryParams = {
                    url: SALES_RETURN.GET_ALL,
                    params: { page, limit, search_key, status, from_date, to_date },
                };

                return queryParams;
            },
        }),
        getAllSecondaryMakePayments: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                search_key?: string;
                from_date?: any,
                to_date?: any
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                search_key,
                from_date,
                to_date
            }) => {
                const queryParams = {
                    url: SALES_RETURN.GET_ALL_MAKE_PAYMENTS,
                    params: { page, limit, search_key, from_date, to_date },
                };

                return queryParams;
            },
        }),
        getSecondarySaleReturn: builder.query<
            any,
            { whole_sales_return_id: string | number }
        >({
            query: ({ whole_sales_return_id }) =>
                `${SALES_RETURN.GET_ONE}${whole_sales_return_id}`,
        }),
        createSecondarySaleReturn: builder.mutation({
            query: (body) => ({
                url: SALES_RETURN.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateSecondarySaleReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${SALES_RETURN.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        createSecondaryMakePayment: builder.mutation({
            query: ({ sales_return_id, body }) => ({
                url: `${SALES_RETURN.MAKE_PAYMENT}${sales_return_id}`,
                method: 'POST',
                body,
            }),
        }),
        deleteSecondarySaleReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${SALES_RETURN.DELETE}${id}`,
                method: 'DELETE',
                body,
            }),
        }),
        deleteSecondaryMakePayment: builder.mutation({
            query: ({id, body}) => ({
                url: `${SALES_RETURN.DELETE_MAKE_PAYMENT}${id}`,
                method: 'DELETE',
                body,
            }),
        }),
        getSaleReturnProductInfo: builder.query<
            any,
            { sale_code: string, sale_type: "whole_sale"|"retail_sale" }
        >({
            query: ({ sale_code, sale_type }) => {
                return `${SALES_RETURN.GET_ALL}/product-info/${sale_code}?sale_type=${sale_type}`;
            },
        }),
        getNewSaleReturnCode: builder.query<Record<string, any>, void>({
            query: () => `${SALES_RETURN.GET_ALL}/new-code`
        })
    }),
    keepUnusedDataFor: 0
});

export const {
    useGetAllSecondarySaleReturnsQuery,
    useGetSecondarySaleReturnQuery,
    useCreateSecondarySaleReturnMutation,
    useUpdateSecondarySaleReturnMutation,
    useDeleteSecondarySaleReturnMutation,
    useCreateSecondaryMakePaymentMutation,
    useGetAllSecondaryMakePaymentsQuery,
    useDeleteSecondaryMakePaymentMutation,
    useLazyGetSaleReturnProductInfoQuery,
    useGetNewSaleReturnCodeQuery
} = secondarySalesReturnApi;
