export const OFFICE_USER = {
    PRIMARY_KEY: 'user_id',
    BASE_PATH: '/user-management/office-user/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    RESET_PASSWORD_PATH(id: string | number) {
        return this.BASE_PATH + id + '/reset-password';
    },
    ADD_PAGE_TITLE: 'Add New User',
    LIST_PAGE_TITLE: 'User List',
    EDIT_PAGE_TITLE: 'Edit User',
    ADD_NEW_TITLE: 'Add New User',
    GET_ALL: '/office-users',
    CREATE: '/office-users/create',
    UPDATE: '/office-users/update/',
    DELETE: '/office-users/delete/',
    CHANGE_PASSWORD: '/office-users/change-password/',
};
