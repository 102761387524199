import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PURCHASE_ORDER } from '@utils/constants/purchase/purchase-order.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const purchaseOrderApi = createApi({
    reducerPath: 'purchaseOrderApi',
    tagTypes: ['PurchaseOrder'],
    baseQuery: baseQueryWithReauth,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getAllPurchaseOrders: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                search_key?: string;
                supplier_id?: number,
                status?: number,
                payment_type_id?: number,
                from_date?: any,
                to_date?: any
            }
        >({
            query: ({ page = 1, limit = 10, search_key, supplier_id, status, payment_type_id, from_date, to_date }) => {
                const queryParams = {
                    url: PURCHASE_ORDER.GET_ALL,
                    params: { page, limit, supplier_id, status, payment_type_id, from_date, to_date },
                };

                if (search_key) {
                    //@ts-ignore
                    queryParams.params.search_key = search_key;
                }

                return queryParams;
            },
        }),
        getPurchaseOrder: builder.query<
            any,
            { purchase_order_id: string | number }
        >({
            query: ({ purchase_order_id }) =>
                `${PURCHASE_ORDER.GET_ONE}${purchase_order_id}`,
        }),
        createPurchaseOrder: builder.mutation({
            query: (body) => ({
                url: PURCHASE_ORDER.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updatePurchaseOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PURCHASE_ORDER.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deletePurchaseOrder: builder.mutation({
            query: (id) => ({
                url: `${PURCHASE_ORDER.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllPurchaseOrdersQuery,
    useGetPurchaseOrderQuery,
    useCreatePurchaseOrderMutation,
    useUpdatePurchaseOrderMutation,
    useDeletePurchaseOrderMutation,
} = purchaseOrderApi;
