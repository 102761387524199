import React, { useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import SearchSelectModal from '../modals/form/SearchSelectModal';

interface CustomInputFieldProps {
    inputType?: string;
    colSpan?: number;
    name: string;
    label?: string;
    disabled?: boolean;
    noLabel?: boolean;
    placeHolder?: string;
    register: UseFormRegister<any>;
    errors: FieldErrors<any>;
    borderColor?: string;
    value?: string;
    showLabelOptional?: boolean;
    setValue: any;
    sliceName?: string;
    dispatchFnc?: unknown;
    data?: unknown[];
    title?: string;
    columnName?: string;
    idName?: string;
    nameName?: string;
    single?: boolean;
    action?: any;
    getValues?: any;
    selectIds?: any[];
    selectId?: string | number;
    required?: boolean;
    columns?: {
        name: string;
        columnName: string;
    }[];
    onSearchChange?: (val: string|undefined|null) => void
}

const CustomSearchModalSelectField: React.FC<CustomInputFieldProps> = ({
    inputType,
    name,
    label,
    disabled,
    noLabel,
    placeHolder,
    register,
    errors,
    colSpan,
    borderColor,
    value,
    showLabelOptional,
    setValue,
    sliceName,
    dispatchFnc,
    data,
    title,
    columnName,
    idName,
    nameName,
    single,
    action,
    getValues,
    selectIds,
    selectId,
    required = false,
    columns,
    onSearchChange

}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [displayValue, setDisplayValue] = useState(value || selectId || '');

    const handleClick = () => {
        setIsOpen(true);
    };

    return (
        <React.Fragment>
            <SearchSelectModal
                setDisplayValue={setDisplayValue}
                title={title || ''}
                data={data!}
                single={single}
                setValue={setValue}
                name={name}
                columnName={columnName || ''}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                idName={idName}
                nameName={nameName}
                action={action}
                getValues={getValues}
                selectIds={selectIds}
                selectId={selectId}
                columns={columns}
                onSearchChange={onSearchChange}
            />
            <div
                style={{
                    gridColumn:
                        colSpan !== 6
                            ? `auto / span ${colSpan || '2'}`
                            : '1 / -1',
                }}
                className={`flex flex-col p-2`}
            >
                {!noLabel ? (
                    <label
                        className="mb-2 text-gray-700"
                        style={{ textTransform: 'capitalize' }}
                        htmlFor="name"
                    >
                        {label || name}
                        {showLabelOptional ? (
                            <span className="text-[0.7rem]"> (Optional)</span>
                        ) : null}
                        {required ? (
                            <span className="ml-[0.15rem] text-red-600 text-md">
                                &#42;
                            </span>
                        ) : null}
                    </label>
                ) : null}
                <label
                    onClick={handleClick}
                    className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
                >
                    <input
                        {...register(name)}
                        disabled={disabled}
                        type="text"
                        readOnly
                        className={`px-2 cursor-pointer py-3 w-full focus:ring-1 ring-primary focus:outline-none border-[0.5px] rounded ${
                            borderColor ? borderColor : 'border-primary-light'
                        }`}
                        placeholder={
                            placeHolder
                                ? placeHolder
                                : `Enter ${label?.toLowerCase() || name}`
                        }
                        value={displayValue}
                        // defaultValue={displayValue}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="currentColor"
                        className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                    >
                        <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                    </svg>
                </label>
                {(errors[name] || (idName && errors[idName])) && (
                    <div className="mt-2 font-[300] italic text-red-600">
                        {/* @ts-ignore */}
                        {errors[name]?.message || errors[idName]?.message }
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default CustomSearchModalSelectField;
