import React, { useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import SearchChooseModal from '../modals/form/SearchChooseModal';

interface CustomInputFieldProps {
    inputType?: string;
    classNames?: string;
    colSpan?: number;
    name: string;
    label?: string;
    disabled?: boolean;
    noLabel?: boolean;
    placeHolder?: string;
    register: UseFormRegister<any>;
    errors: FieldErrors<any>;
    borderColor?: string;
    value?: string;
    showLabelOptional?: boolean;
    setValue: any;
    sliceName?: string;
    dispatchFnc?: unknown;
    data?: unknown[];
    title?: string;
    columnName?: string;
    idName?: string;
    required?: boolean;
    nameName?: string;
    columns?: {
        name: string;
        columnName: string;
    }[];
    onSearchChange?: (val: string|null|undefined) => void
}

const CustomSearchModalChooseField: React.FC<CustomInputFieldProps> = ({
    inputType,
    classNames,
    name,
    label,
    disabled,
    noLabel,
    placeHolder,
    register,
    errors,
    colSpan,
    borderColor,
    value,
    showLabelOptional,
    setValue,
    required,
    sliceName,
    dispatchFnc,
    data,
    title,
    columnName,
    idName,
    nameName,
    columns,
    onSearchChange
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [displayValue, setDisplayValue] = useState(value);

    useEffect(() => {
        if (value) {
            setDisplayValue(value);
        }
    }, [value]);

    const openModal = () => {
        setIsOpen(true);
    };

    const clearValue = () => {
        setDisplayValue(''); // Clear the display value
        setValue(name, undefined); // Set the form field to null
    };

    return (
        <React.Fragment>
            <SearchChooseModal
                setDisplayValue={setDisplayValue}
                title={title || ''}
                data={data!}
                single
                setValue={setValue}
                name={name}
                columnName={columnName || ''}
                isOpen={isOpen && !disabled}
                setIsOpen={setIsOpen}
                idName={idName}
                columns={columns}
                nameName={nameName}
                onSearchChange={onSearchChange}
            />
            <div
                style={{
                    gridColumn:
                        colSpan !== 6
                            ? `auto / span ${colSpan || '2'}`
                            : '1 / -1',
                }}
                className={`flex flex-col p-2 ${classNames}`}
            >
                {!noLabel ? (
                    <label
                        className="mb-2 text-gray-700"
                        style={{ textTransform: 'capitalize' }}
                        htmlFor="name"
                    >
                        {label || name}
                        {showLabelOptional ? (
                            <span className="text-[0.7rem]"> (Optional)</span>
                        ) : null}
                        {required ? (
                            <span className="ml-[0.15rem] text-red-600 text-md">
                                &#42;
                            </span>
                        ) : null}
                    </label>
                ) : null}
                <div className="relative block">
                    <input
                        value={displayValue}
                        disabled={disabled ? disabled : false}
                        type="text"
                        readOnly
                        name="Search"
                        id="search"
                        className={`px-2 cursor-pointer py-3 w-full border-[0.5px] focus:ring-1 ring-primary focus:outline-none rounded ${
                            borderColor ? borderColor : 'border-primary-light'
                        }`}
                        placeholder={
                            placeHolder
                                ? placeHolder
                                : `Enter ${label?.toLowerCase() || name}`
                        }
                    />
                    <svg
                        onClick={openModal}
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="currentColor"
                        className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-10"
                    >
                        <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                    </svg>
                    {displayValue && (
                        <button
                            disabled={disabled}
                            onClick={clearValue}
                            type="button"
                            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            ✕
                        </button>
                    )}
                </div>
                {(
                    <div className="mt-2 font-[300] italic text-red-600">
                        {/* @ts-ignore */}
                        {errors[name]?.message}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default CustomSearchModalChooseField;