import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { SUPPLIER } from '../../utils/constants/supplier.constant';
import { baseQueryWithReauth } from '../auth/auth.api';

export const supplierApi = createApi({
    reducerPath: 'supplerApi',
    tagTypes: ['Supplier'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllSuppliers: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                search_key?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                search_key
            }) => {
                const queryParams = {
                    url: SUPPLIER.GET_ALL,
                    params: { page, limit, search_key },
                };

                // if (customer_first_name) {
                //     //@ts-ignore
                //     queryParams.params.customer_first_name =
                //         customer_first_name;
                // }
                // if (customer_type_id) {
                //     //@ts-ignore
                //     queryParams.params.customer_type_id = customer_type_id;
                // }
                // if (customer_category_id) {
                //     //@ts-ignore
                //     queryParams.params.customer_category_id =
                //         customer_category_id;
                // }

                return queryParams;
            },
        }),
        createSupplier: builder.mutation({
            query: (body) => ({
                url: SUPPLIER.CREATE,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Supplier'],
        }),

        updateSupplier: builder.mutation({
            query: ({ id, body }) => ({
                url: `${SUPPLIER.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Supplier'],
        }),
        
        deleteSupplier: builder.mutation({
            query: (id) => ({
                url: `${SUPPLIER.DELETE}${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Supplier'],
        }),
        getNewSupplierCode: builder.query<Record<string, any>, void>({
            query: () => `${SUPPLIER.GET_ALL}/new-code`
        })
    }),
});

export const {
    useGetAllSuppliersQuery,
    useCreateSupplierMutation,
    useUpdateSupplierMutation,
    useDeleteSupplierMutation,
    useGetNewSupplierCodeQuery
} = supplierApi;
