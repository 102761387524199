import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { WHOLE_INVOICE } from '@/utils/constants/whole-sales-invoice.constants';
import { baseQuery, baseQueryWithReauth } from '@states/auth/auth.api';

export const secondaryInvoiceApi = createApi({
    reducerPath: 'secondaryInvoiceApi',
    tagTypes: ['SecondaryInvoice'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllSecondaryInvoices: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                search_key?: string;
                from_date?: any,
                to_date?: any,
                status?: number
            }
        >({
            query: ({ page = 1, limit = 10, search_key, from_date, to_date, status }) => {
                const queryParams = {
                    url: WHOLE_INVOICE.GET_ALL,
                    params: { page, limit, search_key, from_date, to_date, status },
                };

                return queryParams;
            },
        }),
        getSecondaryInvoice: builder.query<
            any,
            { whole_sales_invoice_id: string | number }
        >({
            query: ({ whole_sales_invoice_id }) => {
                return `${WHOLE_INVOICE.GET_ONE}${whole_sales_invoice_id}`;
            },
        }),
        createSecondaryInvoice: builder.mutation({
            query: (body) => ({
                url: WHOLE_INVOICE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateSecondaryInvoice: builder.mutation({
            query: ({ id, body }) => ({
                url: `${WHOLE_INVOICE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteSecondaryInvoice: builder.mutation({
            query: ({ id, body }) => ({
                url: `${WHOLE_INVOICE.DELETE}${id}`,
                method: 'DELETE',
                body
            }),
        }),
    }),
});

export const {
    useGetAllSecondaryInvoicesQuery,
    useGetSecondaryInvoiceQuery,
    useCreateSecondaryInvoiceMutation,
    useUpdateSecondaryInvoiceMutation,
    useDeleteSecondaryInvoiceMutation,
    useLazyGetSecondaryInvoiceQuery,
} = secondaryInvoiceApi;
