import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { PRODUCT_CATEGORY } from "../../utils/constants/product-category.constants";
import { baseQueryWithReauth } from "../auth/auth.api";

export const productCategoryApi = createApi({
  reducerPath: "productCategoryApi",
  tagTypes: ["ProductCategory"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllProductCategories: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search_key?: string;
      }
    >({
      query: ({ page = 1, limit = 10, search_key }) => {
        const queryParams = {
          url: PRODUCT_CATEGORY.GET_ALL,
          params: { page, limit },
        };

        if (search_key) {
          //@ts-ignore
          queryParams.params.search_key = search_key;
        }

        return queryParams;
      },
    }),
    createProductCategory: builder.mutation({
      query: (body) => ({
        url: PRODUCT_CATEGORY.CREATE,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProductCategory"],
    }),
    updateProductCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `${PRODUCT_CATEGORY.UPDATE}/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["ProductCategory"],
    }),
    deleteProductCategory: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT_CATEGORY.DELETE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductCategory"],
    }),
  }),
  keepUnusedDataFor: 0
});

export const {
  useGetAllProductCategoriesQuery,
  useLazyGetAllProductCategoriesQuery,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation,
} = productCategoryApi;
