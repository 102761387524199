import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { baseQueryWithReauth } from '../auth/auth.api';

export const commonApi = createApi({
    reducerPath: 'commonApi',
    tagTypes: ['UserRole', 'CustomerHistory', "StandardAppSchema", "Channel"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllUserRoles: builder.query<[], void>({
            query: () => 'user-roles',
        }),
        createUserRole: builder.mutation({
            query: (body) => ({
                url: 'user-roles/create',
                method: 'POST',
                body,
            }),
        }),
        getAllModules: builder.query<
            [],
            {
                channel_id?: number;
            }
        >({
            query: ({ channel_id }) => {
                const queryParams = {
                    url: 'module',
                    params: { channel_id: 1 },
                };

                if (channel_id) {
                    queryParams.params.channel_id = channel_id;
                }

                return queryParams;
            },
        }),
        getAllCustomerTypes: builder.query<[], void>({
            query: () => 'common/customer-type',
        }),
        getAllCreditTypes: builder.query<[], void>({
            query: () => 'common/credit-type',
        }),
        getAllPaymentTypes: builder.query<[], void>({
            query: () => 'general/payment-type',
        }),
        getAllProductTypes: builder.query<[], void>({
            query: () => 'common/product-type',
        }),
        getAllPaymentTerms: builder.query<[], void>({
            query: () => 'general/payment-terms',
        }),
        getAllStates: builder.query<[], void>({
            query: () => 'common/location',
        }),
        getAllCountries: builder.query<[], void>({
            query: () => 'general/countries'
        }),
        getAllSubModulesByModuleId: builder.mutation({
            query: (body) => ({
                url: 'sub-module?limit=1000',
                method: 'POST',
                body,
            }),
        }),
        getCustomerAllCreditLimits: builder.query<
            [],
            {
                customerId: string | number;
            }
        >({
            query: ({ customerId }) =>
                `common/customer-credit-limit/${customerId}`,
            providesTags: ['CustomerHistory'],
        }),
        getStandardAppModuleSchema: builder.query<
            [],
            {
                channelId: string|number
            }
        >({
            query: ({ channelId = -1 }) => 
                `general/standard-modules?channelId=${channelId}`,
            providesTags: ["StandardAppSchema"]
        }),
        getAllChannels: builder.query<
            [],
            void
        >({
            query: () => 
                `general/channels`,
            providesTags: ["Channel"]
        }),
        getAllCountry: builder.query<[], { search_key?: string }>({
            query: ({ search_key }) => {
                const queryParams = {
                    url: 'general/country',
                    params: { },
                };

                if (search_key) {
                    //@ts-ignore
                    queryParams.params.search_key = search_key;
                }

                return queryParams;
            },
        }),
        getAllState: builder.query<[], { search_key?: string, country_id?: number }>({
            query: ({ search_key, country_id }) => {
                const queryParams = {
                    url: 'general/state',
                    params: { },
                };

                if (search_key) {
                    //@ts-ignore
                    queryParams.params.search_key = search_key;
                }

                if (country_id) {
                    //@ts-ignore
                    queryParams.params.country_id = country_id;
                }

                return queryParams;
            }
        }),
        getAllCity: builder.query<[], { search_key?: string, state_id?: number }>({
            query: ({ search_key, state_id }) => {
                const queryParams = {
                    url: 'general/city',
                    params: { },
                };

                if (search_key) {
                    //@ts-ignore
                    queryParams.params.search_key = search_key;
                }

                if (state_id) {
                    //@ts-ignore
                    queryParams.params.state_id = state_id;
                }

                return queryParams;
            }
        })
    }),
});

export const {
    useGetAllUserRolesQuery,
    useGetAllModulesQuery,
    useLazyGetAllModulesQuery,
    useGetAllStatesQuery,
    useGetAllCountriesQuery,
    useCreateUserRoleMutation,
    useGetAllPaymentTermsQuery,
    useGetAllPaymentTypesQuery,
    useGetAllProductTypesQuery,
    useGetAllCreditTypesQuery,
    useGetAllSubModulesByModuleIdMutation,
    useGetAllCustomerTypesQuery,
    useGetCustomerAllCreditLimitsQuery,
    useGetStandardAppModuleSchemaQuery,
    useGetAllChannelsQuery,
    useGetAllCountryQuery,
    useGetAllStateQuery,
    useGetAllCityQuery
} = commonApi;
