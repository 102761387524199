import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../auth/auth.api";
import { DASHBOARD } from "../../utils/constants/dashboard.constant";
import { TSuccessResponse } from "../../types/general/TSuccessResponse";
import { TSaleSummary } from "../../types/dashboard/TSaleSummary";
import { TSuccessResponse2 } from "../../types/general/TSuccessResponse2";
import { TProductSale } from "../../types/dashboard/TProductSale";

export const dashboardApi = createApi({
    reducerPath: 'dashboard',
    tagTypes: ['Dashboard'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getSaleSummary: builder.query<
            TSuccessResponse2<TSaleSummary>,
            void
        >({
            query: () => {
                return DASHBOARD.GET_SALE_SUMMARY
            },
        }),

        getTopProductSale: builder.query<
            TSuccessResponse2<TProductSale[]>, 
            { interval: "monthly"|"weekly", month: number, week: number|null }
        >({
            query: ({ interval, month, week }) => 
                `${DASHBOARD.GET_TOP_PRODUCT_SALE}?interval=${interval}&month=${month}&week=${week}`
        }),

        getSaleChartData: builder.query<any[], { interval: "monthly"|"weekly" }>({
            query: ({ interval }) => 
                `${DASHBOARD.GET_SALE}?interval=${interval}`
        }),

        getPorfitAndLoss: builder.query<any[], { interval: "monthly"|"weekly" }>({
            query: ({ interval }) => 
                `${DASHBOARD.GET_PROFIT_LOSS}?interval=${interval}`
        })
    }),
});

export const {
    useGetSaleSummaryQuery,
    useGetTopProductSaleQuery,
    useGetSaleChartDataQuery,
    useGetPorfitAndLossQuery
} = dashboardApi;