import { createApi } from "@reduxjs/toolkit/query/react";
import { PURCHASE_ORDER_DETAIL } from "@/utils/constants/report/purchase-order-detail.constants";
import { baseQueryWithReauth } from "../auth/auth.api";
import { SALES_BY_PRODUCT } from "../../utils/constants/report/sales-by-product.constants";
import { SALES_BY_PRODUCT_CATEGORY } from "../../utils/constants/report/sales-by-product-category.constants";
import { SALES_BY_CUSTOMER } from "../../utils/constants/report/sales-by-customer.constants";
import { SALES_DETAIL } from "../../utils/constants/report/sales-detail.constant";
import { PURCHASE_ORDER_BY_SUPPLIER } from "../../utils/constants/report/purchase-order-by-supplier.constants";
import { STOCK_TRANSFER_REPORT } from "../../utils/constants/report/stock-transfer.constants";
import { STOCK_BALANCE_REPORT } from "../../utils/constants/report/stock-balance.constants";
import { STOCK_ADJUSTMENT_REPORT } from "../../utils/constants/report/stock-adjustment.constants";

export const reportApi = createApi({
    reducerPath: "reportApi",
    tagTypes: ["reports"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getPurchaseDetail: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: PURCHASE_ORDER_DETAIL.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),

        getSalesByProduct: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: SALES_BY_PRODUCT.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),

        getSalesByProductCategory: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: SALES_BY_PRODUCT_CATEGORY.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),

        getSalesByCustomer: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: SALES_BY_CUSTOMER.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),

        getSalesDetail: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: SALES_DETAIL.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),

        getPurchaseBySupplier: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: PURCHASE_ORDER_BY_SUPPLIER.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),

        getStockTransfer: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: STOCK_TRANSFER_REPORT.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),

        getStockBalance: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: STOCK_BALANCE_REPORT.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),

        getStockAdjustment: builder.query<
            [],
            {
                from_date?: any,
                to_date?: any,
            }
        >({
            query: ({ from_date, to_date }) => {
                const queryParams = {
                    url: STOCK_ADJUSTMENT_REPORT.GET_ALL,
                    params: { from_date, to_date },
                };

                return queryParams;
            },
        }),
    }),
});

export const {
    useGetPurchaseDetailQuery,
    useGetSalesByProductQuery,
    useGetSalesByProductCategoryQuery,
    useGetSalesByCustomerQuery,
    useGetSalesDetailQuery,
    useGetPurchaseBySupplierQuery,
    useGetStockTransferQuery,
    useGetStockBalanceQuery,
    useGetStockAdjustmentQuery,
} = reportApi;