import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { WAREHOUSE } from '@utils/constants/warehouse.constants';
import { WAREHOUSE_USER_ASSIGN } from '@utils/constants/warehouse-user-assign.constants';
import { baseQuery, baseQueryWithReauth } from '../auth/auth.api';

export const warehouseApi = createApi({
    reducerPath: 'warehouseApi',
    tagTypes: ['Warehouse'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllWarehouses: builder.query<
            [],
            { page?: number; limit?: number; warehouse_name?: string, shop_id?: number }
        >({
            query: ({ page = 1, limit = 10, warehouse_name, shop_id }) => {
                const queryParams = {
                    url: WAREHOUSE.GET_ALL,
                    params: { page, limit, shop_id },
                };

                if (warehouse_name) {
                    //@ts-ignore
                    queryParams.params.warehouse_name = warehouse_name;
                }

                if (shop_id) {
                    //@ts-ignore
                    queryParams.params.shop_id = shop_id
                }

                return queryParams;
            },
        }),
        createWarehouse: builder.mutation({
            query: (body) => ({
                url: WAREHOUSE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateWarehouse: builder.mutation({
            query: ({ id, body }) => ({
                url: `${WAREHOUSE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteWarehouse: builder.mutation({
            query: (id) => ({
                url: `${WAREHOUSE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
        getNewWarehouseCode: builder.query<Record<string, any>, void>({
            query: () => `${WAREHOUSE.GET_ALL}/new-code`
        }),
        getAssignedWarehouses: builder.query<Record<string, any>, void>({
            query: () => `${WAREHOUSE.GET_ALL}/filter`
        })
    }),
    keepUnusedDataFor: 0
});

export const {
    useGetAllWarehousesQuery,
    useCreateWarehouseMutation,
    useUpdateWarehouseMutation,
    useDeleteWarehouseMutation,
    useGetNewWarehouseCodeQuery,
    useGetAssignedWarehousesQuery
} = warehouseApi;
