export const PURCHASE_RETURN = {
    BASE_PATH: "/purchase-return/purchase-return/",
    BASE_PATH_MAKE_PAYMENT: '/purchase-return/payment-receive/',
    MAKE_PAYMENT_RECEIVE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + "/edit/make-payment";
    },
    ADD_PATH() {
        return this.BASE_PATH + "add";
    },
    ADD_BUTTON_TITLE: "Add New Purchase Return",
    LIST_PAGE_TITLE: "Purchase Return List",
    EDIT_PAGE_TITLE: "Edit Purchase Return",
    GET_ALL: "purchase-return",
    GET_ONE: "purchase-return/edit/",
    CREATE: "purchase-return/create",
    UPDATE: "purchase-return/update/",
    DELETE: "purchase-return/delete/",
    ADD_NEW_TITLE: "Add New Purchase Return",

    GET_ALL_MAKE_PAYMENTS: "purchase-return/payment",
    MAKE_PAYMENT: 'purchase-return/payment/',
    DELETE_MAKE_PAYMENT: 'purchase-return/payment/delete/',
};
