import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { STOCK_TRANSFER } from '@utils/constants/warehouse-and-inventory/stock-transfer.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const stockTransferApi = createApi({
    reducerPath: 'stockTransferApi',
    tagTypes: ['StockTransfer'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllStockTransfers: builder.query<
            [],
            { page?: number; limit?: number, search_key?: string, from_date?: any, to_date?: any, warehouse_id?: number, method?: string  }
        >({
            query: ({ page = 1, limit = 10, search_key, from_date, to_date, warehouse_id, method }) => {
                const queryParams = {
                    url: STOCK_TRANSFER.GET_ALL,
                    params: { page, limit, search_key, from_date, to_date, warehouse_id, method },
                };

                return queryParams;
            },
        }),
        getStockTransfer: builder.query<any, { id: string | number }>({
            query: ({ id }) => STOCK_TRANSFER.GET_ONE + id,
        }),
        createStockTransfer: builder.mutation({
            query: (body) => ({
                url: STOCK_TRANSFER.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateStockTransfer: builder.mutation({
            query: ({ id, body }) => ({
                url: `${STOCK_TRANSFER.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteStockTransfer: builder.mutation({
            query: (id) => ({
                url: `${STOCK_TRANSFER.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllStockTransfersQuery,
    useGetStockTransferQuery,
    useCreateStockTransferMutation,
    useUpdateStockTransferMutation,
    useDeleteStockTransferMutation,
} = stockTransferApi;
