import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { STOCK_BALANCE } from '@utils/constants/warehouse-and-inventory/stock-balance.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const stockBalanceApi = createApi({
    reducerPath: 'stockBalanceApi',
    tagTypes: ['StockBalance'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllStockBalances: builder.query<
            [],
            { page?: number; limit?: number, product_category_id?: number, product_id?: number, warehouse_id?: number, search_key?: string }
        >({
            query: ({ page = 1, limit = 10, product_category_id, product_id, warehouse_id, search_key }) => {
                const queryParams = {
                    url: STOCK_BALANCE.GET_ALL,
                    params: { page, limit, product_category_id, product_id, warehouse_id, search_key },
                };

                return queryParams;
            },
        })
    }),
    keepUnusedDataFor: 0,
});

export const { useGetAllStockBalancesQuery } = stockBalanceApi;
