export const supplierFields = ({
    stateData,
    stateSelectAction,
    cities,
    citySelectAction,
    countries,
    countrySelectAction,
    cityDisabled,
    stateDisabled,
    
    divisionValue,
    cityValue,
    countryValue,
    supplierCodeValue,

    divisionSelectId,
    citySelectId,
    countrySelectId,
}: {
    stateData?: any[];
    stateSelectAction?: any;
    cities?: any[];
    citySelectAction?: any;
    countries?: any[];
    countrySelectAction?: any;
    cityDisabled?: boolean;
    stateDisabled?: boolean;
    creditTypeValue?: string | number;
    divisionValue?: string;
    cityValue?: string;
    countryValue?: string;
    divisionSelectId?: string | number;
    citySelectId?: string | number;
    countrySelectId?: string | number;
    supplierCodeValue?: any;
   
    setShowHistoryModal?: any;
}) => {
    const states = stateData;

    return [
        {
            name: 'supplier_code',
            type: 'text',
            label: 'Supplier ID',
            required: true,
            disabled: true,
            value: supplierCodeValue
        },
        {
            name: 'supplier_name',
            type: 'text',
            label: 'Supplier Name',
            required: true,
        },
       
        {
            name: 'supplier_phone1',
            type: 'text',
            label: 'Phone Number',
            required: true,
        },
        {
            name: 'supplier_email',
            type: 'text',
            label: 'Supplier Email',
            showLabelOptional: true,
            placeHolder: 'Enter supplier email',
        },
        {
            name: 'contact_first_name',
            type: 'text',
            label: 'Contact Person First Name',
            showLabelOptional: true,
            placeHolder: 'Enter first name',
        },
        {
            name: 'contact_last_name',
            type: 'text',
            label: 'Contact Person Last Name',
            showLabelOptional: true,
            placeHolder: 'Enter last name',
        },
        {
            name: 'contact_phoneno',
            type: 'text',
            label: 'Contact Person Phone Number',
            showLabelOptional: true,
            placeHolder: 'Enter phone number',
        },
        {
            name: 'contact_email',
            type: 'text',
            label: 'Contact Person Email',
            showLabelOptional: true,
            placeHolder: 'Enter email',
        },
        {
            name: 'Add supplier Detail',
            type: 'secondary-title',
        },
        {
            name: "country_name",
            title: "Country List",
            columnName: "Country Name",
            idName: "country_id",
            nameName: "country_name",
            type: "search-modal",
            label: "Country Name",
            placeHolder: "Country",
            data: countries?.map((c: any) => ({
                country_id: c.country_id,
                country_name: c.country_name,
            })),
            single: true,
            action: countrySelectAction,
            value: countryValue,
            selectId: countrySelectId,
            required: true,
            columns: [
                {
                    name: "country_name",
                    columnName: "Country Name",
                },
            ],
        },
        {
            name: "state_name",
            title: "Division/State List",
            columnName: "Division / State Name",
            idName: "state_id",
            nameName: "state_name",
            type: "search-modal",
            label: "Division/State Name",
            placeHolder: "Division/State",
            required: true,
            data: states?.map((s: any) => ({
                state_id: s.state_id,
                state_name: s.state_name,
                country_id: s.country_id,
                country_name: s.country_name
            })),
            single: true,
            action: stateSelectAction,
            value: divisionValue,
            selectId: divisionSelectId,
            columns: [
                {
                    name: "state_name",
                    columnName: "State/Division Name",
                },
                {
                    name: "country_name",
                    columnName: "Country Name",
                },
            ],
        },
        {
            name: "city_name",
            title: "City List",
            columnName: "City Name",
            idName: "city_id",
            nameName: "city_name",
            type: "search-modal",
            label: "City Name",
            placeHolder: "City",
            required: true,
            data: cities?.map((c) => ({
                city_id: c.city_id,
                city_name: c.city_name,
                state_id: c.state_id,
                state_name: c.state_name,
                country_id: c.country_id,
                country_name: c.country_name
            })),
            single: true,
            action: citySelectAction,
            disabled: cityDisabled,
            value: cityValue,
            selectId: citySelectId,
            columns: [
                {
                    name: "city_name",
                    columnName: "City Name",
                },
                {
                    name: "state_name",
                    columnName: "State/Division Name",
                },
                {
                    name: "country_name",
                    columnName: "Country Name",
                },
            ],
        },
        {
            name: 'supplier_address',
            type: 'text',
            label: 'Address',
            colSpan: 6,
            required: true,
        },
       
       
        {
            name: 'remark',
            type: 'textarea',
            colSpan: 3,
        },
        {
            name: 'description',
            type: 'textarea',
            colSpan: 3,
        },
    ];
};
